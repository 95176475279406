import React from "react";
import { Stack, Main, Sidebar } from "@layout";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import NewsletterExpanded from "@widgets/NewsletterExpanded";
import { Text } from "theme-ui";

export default ({ ...props }) => {
  const { pageContext: { services = {} } = {} } = props;

  return (
    <>
      <Seo title="Privacy" />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header="Privacy 🕵️ 🔎"
            subheader="Updated: January 9th, 2021"
          />
          <Text variant="p">
            Thank you for choosing to be part of our community at Devclass
            ("Company", "we", "us", "our"). We are committed to protecting your
            personal information and your right to privacy. If you have any
            questions or concerns about this privacy notice, or our practices
            with regards to your personal information, please contact us at
            assaf.elovic@gmail.com. When you visit our website
            https://devclass.io (the "Website"), and more generally, use any of
            our services (the "Services", which include the Website), we
            appreciate that you are trusting us with your personal information.
            We take your privacy very seriously. In this privacy notice, we seek
            to explain to you in the clearest way possible what information we
            collect, how we use it and what rights you have in relation to it.
            We hope you take some time to read through it carefully, as it is
            important. If there are any terms in this privacy notice that you do
            not agree with, please discontinue use of our Services immediately.
            This privacy notice applies to all information collected through our
            Services (which, as described above, includes our Website), as well
            as, any related services, sales, marketing or events. Please read
            this privacy notice carefully as it will help you understand what we
            do with the information that we collect.
          </Text>
          <h2>1. What information do we collect?</h2>
          <Text variant="p">
            Information automatically collected In Short:  Some information —
            such as your Internet Protocol (IP) address and/or browser and
            device characteristics — is collected automatically when you visit
            our Website. We automatically collect certain information when you
            visit, use or navigate the Website. This information does not reveal
            your specific identity (like your name or contact information) but
            may include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Website and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Website, and for our
            internal analytics and reporting purposes. Like many businesses, we
            also collect information through cookies and similar technologies.
            The information we collect includes: Log and Usage Data. Log and
            usage data is service-related, diagnostic, usage and performance
            information our servers automatically collect when you access or use
            our Website and which we record in log files. Depending on how you
            interact with us, this log data may include your IP address, device
            information, browser type and settings and information about your
            activity in the Website (such as the date/time stamps associated
            with your usage, pages and files viewed, searches and other actions
            you take such as which features you use), device event information
            (such as system activity, error reports (sometimes called 'crash
            dumps') and hardware settings). Device Data. We collect device data
            such as information about your computer, phone, tablet or other
            device you use to access the Website. Depending on the device used,
            this device data may include information such as your IP address (or
            proxy server), device and application identification numbers,
            location, browser type, hardware model Internet service provider
            and/or mobile carrier, operating system and system configuration
            information. Location Data. We collect location data such as
            information about your device's location, which can be either
            precise or imprecise. How much information we collect depends on the
            type and settings of the device you use to access the Website. For
            example, we may use GPS and other technologies to collect
            geolocation data that tells us your current location (based on your
            IP address). You can opt out of allowing us to collect this
            information either by refusing access to the information or by
            disabling your Location setting on your device. Note however, if you
            choose to opt out, you may not be able to use certain aspects of the
            Services.
          </Text>
          <h2>2. How do we use your information?</h2>
          <Text variant="p">
            In Short:  We process your information for purposes based on
            legitimate business interests, the fulfillment of our contract with
            you, compliance with our legal obligations, and/or your consent. We
            use personal information collected via our Website for a variety of
            business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate
            business interests, in order to enter into or perform a contract
            with you, with your consent, and/or for compliance with our legal
            obligations. We indicate the specific processing grounds we rely on
            next to each purpose listed below. We use the information we collect
            or receive: To facilitate account creation and logon process. If you
            choose to link your account with us to a third-party account (such
            as your Google or Facebook account), we use the information you
            allowed us to collect from those third parties to facilitate account
            creation and logon process for the performance of the contract. To
            post testimonials. We post testimonials on our Website that may
            contain personal information. Prior to posting a testimonial, we
            will obtain your consent to use your name and the content of the
            testimonial. If you wish to update, or delete your testimonial,
            please contact us at assaf.elovic@gmail.com and be sure to include
            your name, testimonial location, and contact information. Request
            feedback. We may use your information to request feedback and to
            contact you about your use of our Website. To enable user-to-user
            communications. We may use your information in order to enable
            user-to-user communications with each user's consent. To manage user
            accounts. We may use your information for the purposes of managing
            our account and keeping it in working order. To send administrative
            information to you. We may use your personal information to send you
            product, service and new feature information and/or information
            about changes to our terms, conditions, and policies. To protect our
            Services. We may use your information as part of our efforts to keep
            our Website safe and secure (for example, for fraud monitoring and
            prevention). To enforce our terms, conditions and policies for
            business purposes, to comply with legal and regulatory requirements
            or in connection with our contract. To respond to legal requests and
            prevent harm. If we receive a subpoena or other legal request, we
            may need to inspect the data we hold to determine how to respond.
          </Text>
          <h2>3. Will your information be shared with anyone?</h2>
          <Text variant="p">
            In Short:  We only share information with your consent, to comply
            with laws, to provide you with services, to protect your rights, or
            to fulfill business obligations. We may process or share your data
            that we hold based on the following legal basis: Consent: We may
            process your data if you have given us specific consent to use your
            personal information for a specific purpose. Legitimate Interests:
            We may process your data when it is reasonably necessary to achieve
            our legitimate business interests. Performance of a Contract: Where
            we have entered into a contract with you, we may process your
            personal information to fulfill the terms of our contract. Legal
            Obligations: We may disclose your information where we are legally
            required to do so in order to comply with applicable law,
            governmental requests, a judicial proceeding, court order, or legal
            process, such as in response to a court order or a subpoena
            (including in response to public authorities to meet national
            security or law enforcement requirements). Vital Interests: We may
            disclose your information where we believe it is necessary to
            investigate, prevent, or take action regarding potential violations
            of our policies, suspected fraud, situations involving potential
            threats to the safety of any person and illegal activities, or as
            evidence in litigation in which we are involved. More specifically,
            we may need to process your data or share your personal information
            in the following situations: Business Transfers. We may share or
            transfer your information in connection with, or during negotiations
            of, any merger, sale of company assets, financing, or acquisition of
            all or a portion of our business to another company.
          </Text>
          <h2>4. Do we use cookies and other tracking technologies?</h2>
          <Text variant="p">
            In Short:  We may use cookies and other tracking technologies to
            collect and store your information. We may use cookies and similar
            tracking technologies (like web beacons and pixels) to access or
            store information. Specific information about how we use such
            technologies and how you can refuse certain cookies is set out in
            our Cookie Notice.
          </Text>
          <h2>5. Is your information transferred internationally? </h2>
          <Text variant="p">
            In Short:  We may transfer, store, and process your information in
            countries other than your own. Our servers are located in. If you
            are accessing our Website from outside, please be aware that your
            information may be transferred to, stored, and processed by us in
            our facilities and by those third parties with whom we may share
            your personal information (see "WILL YOUR INFORMATION BE SHARED WITH
            ANYONE?" above), in and other countries. If you are a resident in
            the European Economic Area, then these countries may not necessarily
            have data protection laws or other similar laws as comprehensive as
            those in your country. We will however take all necessary measures
            to protect your personal information in accordance with this privacy
            notice and applicable law.
          </Text>
          <h2>6. What is our stance on third-party websites?</h2>
          <Text variant="p">
            In Short:  We are not responsible for the safety of any information
            that you share with third-party providers who advertise, but are not
            affiliated with, our Website. The Website may contain advertisements
            from third parties that are not affiliated with us and which may
            link to other websites, online services or mobile applications. We
            cannot guarantee the safety and privacy of data you provide to any
            third parties. Any data collected by third parties is not covered by
            this privacy notice. We are not responsible for the content or
            privacy and security practices and policies of any third parties,
            including other websites, services or applications that may be
            linked to or from the Website. You should review the policies of
            such third parties and contact them directly to respond to your
            questions.
          </Text>
          <h2>7. How long do we keep your information?</h2>
          <Text variant="p">
            In Short:  We keep your information for as long as necessary to
            fulfill the purposes outlined in this privacy notice unless
            otherwise required by law. We will only keep your personal
            information for as long as it is necessary for the purposes set out
            in this privacy notice, unless a longer retention period is required
            or permitted by law (such as tax, accounting or other legal
            requirements). No purpose in this notice will require us keeping
            your personal information for longer than the period of time in
            which users have an account with us. When we have no ongoing
            legitimate business need to process your personal information, we
            will either delete or anonymize such information, or, if this is not
            possible (for example, because your personal information has been
            stored in backup archives), then we will securely store your
            personal information and isolate it from any further processing
            until deletion is possible.
          </Text>
          <h2>8. How do we keep your information safe?</h2>
          <Text variant="p">
            In Short:  We aim to protect your personal information through a
            system of organizational and technical security measures. We have
            implemented appropriate technical and organizational security
            measures designed to protect the security of any personal
            information we process. However, despite our safeguards and efforts
            to secure your information, no electronic transmission over the
            Internet or information storage technology can be guaranteed to be
            100% secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able
            to defeat our security, and improperly collect, access, steal, or
            modify your information. Although we will do our best to protect
            your personal information, transmission of personal information to
            and from our Website is at your own risk. You should only access the
            Website within a secure environment.
          </Text>
          <h2>9. What are your privacy rights?</h2>
          <Text variant="p">
            In Short:  You may review, change, or terminate your account at any
            time.   If you are a resident in the European Economic Area and you
            believe we are unlawfully processing your personal information, you
            also have the right to complain to your local data protection
            supervisory authority. You can find their contact details here:
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
            If you are a resident in Switzerland, the contact details for the
            data protection authorities are available here:
            https://www.edoeb.admin.ch/edoeb/en/home.html. If you have questions
            or comments about your privacy rights, you may email us at
            assaf.elovic@gmail.com. Account Information If you would at any time
            like to review or change the information in your account or
            terminate your account, you can: Log in to your account settings and
            update your user account. Contact us using the contact information
            provided. Upon your request to terminate your account, we will
            deactivate or delete your account and information from our active
            databases. However, we may retain some information in our files to
            prevent fraud, troubleshoot problems, assist with any
            investigations, enforce our Terms of Use and/or comply with
            applicable legal requirements. Cookies and similar technologies:
            Most Web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies
            and to reject cookies. If you choose to remove cookies or reject
            cookies, this could affect certain features or services of our
            Website. To opt-out of interest-based advertising by advertisers on
            our Website visit http://www.aboutads.info/choices/. Opting out of
            email marketing: You can unsubscribe from our marketing email list
            at any time by clicking on the unsubscribe link in the emails that
            we send or by contacting us using the details provided below. You
            will then be removed from the marketing email list — however, we may
            still communicate with you, for example to send you service-related
            emails that are necessary for the administration and use of your
            account, to respond to service requests, or for other non-marketing
            purposes. To otherwise opt-out, you may: Access your account
            settings and update your preferences. Contact us using the contact
            information provided.
          </Text>
          <h2>10. Controls for do-not-track features</h2>
          <Text variant="p">
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice. 
          </Text>
          <h2>11. Do california residents have specific privacy rights?</h2>
          <Text variant="p">
            In Short:  Yes, if you are a resident of California, you are granted
            specific rights regarding access to your personal information.
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below. If you are under 18 years of age, reside
            in California, and have a registered account with the Website, you
            have the right to request removal of unwanted data that you publicly
            post on the Website. To request removal of such data, please contact
            us using the contact information provided below, and include the
            email address associated with your account and a statement that you
            reside in California. We will make sure the data is not publicly
            displayed on the Website, but please be aware that the data may not
            be completely or comprehensively removed from all our systems (e.g.
            backups, etc.).
          </Text>
          <h2>12. Do we make updates to this notice?</h2>
          <Text variant="p">
            In Short:  Yes, we will update this notice as necessary to stay
            compliant with relevant laws. We may update this privacy notice from
            time to time. The updated version will be indicated by an updated
            "Revised" date and the updated version will be effective as soon as
            it is accessible. If we make material changes to this privacy
            notice, we may notify you either by prominently posting a notice of
            such changes or by directly sending you a notification. We encourage
            you to review this privacy notice frequently to be informed of how
            we are protecting your information.
          </Text>
          <h2>13. How can you contact us about this notice?</h2>
          <Text variant="p">
            If you have questions or comments about this notice, you may email
            us at arieg419@gmail.com
          </Text>
          <h2>
            14. How can you review, update, or delete the data we collect from
            you?
          </h2>
          <Text variant="p">
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please submit a request form by clicking here. We will respond to
            your request within 30 days.
          </Text>
          <Divider />
        </Main>
        <Divider />
        <Sidebar>
          {services.mailchimp && (
            <>
              <NewsletterExpanded simple />
            </>
          )}
        </Sidebar>
      </Stack>
    </>
  );
};
